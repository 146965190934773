import React, { Suspense } from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from '@tmosystems/ggtmo-theme';
import Router from '../Router';
import { Main } from './styled';

const App = () => (
  <Main>
    <Suspense fallback={<></>}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ThemeProvider>
    </Suspense>
  </Main>
);

export default App;
