import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';

import App from './App';
import * as serviceWorker from './serviceWorker';

import './styles/scss/app.scss';

toast.configure({
  autoClose: 2000,
  hideProgressBar: true,
  pauseOnHover: false,
  pauseOnFocusLoss: false,
  position: 'top-right',
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
