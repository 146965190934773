import React from 'react';

import { Switch, Route } from 'react-router-dom';
import HousingNeeds from './forms/HousingNeeds';
import CLHDSurvey from './forms/CLHDSurvey';
import AGMSurvey from './forms/AGMSurvey';
import BPDSurvey from './forms/BPDSurvey';

const Router = () => (
  <Switch>
    <Route exact path="/housing-needs" component={HousingNeeds} />
    <Route exact path="/community-led-housing-development-survey" component={CLHDSurvey} />
    <Route exact path="/agm-registration" component={AGMSurvey} />
    <Route exact path="/bpd-survey" component={BPDSurvey} />
  </Switch>
);

export default Router;
