import React from 'react';
import { useMedia } from 'react-media-match';

const MobileComponent = React.lazy<React.FC>(() => import('./mobile'));
const DesktopComponent = React.lazy<React.FC>(() => import('./desktop'));

const CLHDSurvey = () =>
  useMedia<JSX.Element>({
    mobile: <MobileComponent />,
    desktop: <DesktopComponent />,
  });

export default CLHDSurvey;
